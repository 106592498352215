<template>
  <div>
    <el-row>
      <el-button type="primary">随机抽查</el-button>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-form inline :model="inspectCondition">
        <!-- 编号/名称 -->
        <el-form-item
          class="formItemBoxStyle"
          label="名称/编号"
          prop="inspectCodeName"
        >
          <el-input
            style="width: 200px"
            v-model="inspectCondition.inspectCodeName"
            placeholder="请输入"
          />
        </el-form-item>
        <!-- 执行学校 -->
        <el-form-item class="formItemBoxStyle" prop="school" label="执行学校">
          <el-input
            style="width: 200px"
            v-model="inspectCondition.school"
            placeholder="请输入"
          />
        </el-form-item>
        <!-- 危化品 -->
        <el-form-item label="状态" class="formItemBoxStyle" prop="status">
          <el-select style="width: 200px" v-model="inspectCondition.status">
            <el-option label="全部" value=""></el-option>
            <el-option label="已提交" :value="1"></el-option>
            <el-option label="未提交" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <!-- 按钮区域 -->
        <el-form-item>
          <el-button @click="handleResetTable">重置</el-button>
          <el-button @click="handleQueryTable" type="primary">查询</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-table
        :data="safetyInspectionDataList"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column label="任务编号" prop="taskCode"></el-table-column>
        <el-table-column label="任务名称" prop="taskName"></el-table-column>
        <el-table-column label="执行学校" prop="taskSchool"></el-table-column>
        <el-table-column label="巡查项" prop="taskItem"></el-table-column>
        <el-table-column label="已完成" prop="taskDone"></el-table-column>
        <el-table-column label="未完成" prop="taskunDone"></el-table-column>
        <el-table-column label="巡查达标率" prop="taskReachStandard">
          <template slot-scope="scope">
            <div
              class="taskPoint"
              :class="
                scope.row.taskDone / scope.row.taskItem >= 0.9 ? 'green' : ''
              "
            ></div>
            <span>
              {{
                Math.floor((scope.row.taskDone * 100) / scope.row.taskItem)
              }}%</span
            >
          </template>
        </el-table-column>
        <el-table-column label="完成时间" prop="taskTime"></el-table-column>
        <el-table-column label="状态"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span
              class="leftBtn"
              @click="handleCheckInspectionDetail(scope.row)"
              style="padding-right: 0"
              >查看</span
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 分页组件 -->
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChangeSafetyInspectionPageInfo"
        @current-change="handleCurrentChangeSafetyInspectionPageInfo"
        :current-page="safetyInspectionPageInfo.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="safetyInspectionPageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="safetyInspectionPageInfo.pageTotal"
      ></el-pagination>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inspectCondition: {
        inspectCodeName: "",
        school: "",
        status: "",
      },
      safetyInspectionDataList: [
        // { taskItem: 300, taskDone: 290 },
        // { taskItem: 300, taskDone: 200 },
      ],
      safetyInspectionPageInfo: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
    };
  },
  methods: {
    // 获取数据
    fetchSafetyInspectionData() {},
    // 跳转至详情页
    handleCheckInspectionDetail(row) {
      this.$router.push({
        path: `/safeInspect/inspectDetail?
        inspectInfo=${JSON.stringify(row)}`,
      });
    },
    // 获取表格重置
    handleResetTable() {
      this.inspectCondition = {
        inspectCodeName: "",
        school: "",
        status: "",
      };
      this.handleQueryTable();
    },
    // 获取表格查询
    handleQueryTable() {
      this.safetyInspectionPageInfo.pageIndex = 1;
      this.safetyInspectionPageInfo.pageSize = 10;
      this.fetchSafetyInspectionData();
    },
    // 分页事件
    handleSizeChangeSafetyInspectionPageInfo(val) {
      this.safetyInspectionPageInfo.pageIndex = 1;
      this.safetyInspectionPageInfo.pageSize = val;
      this.fetchSafetyInspectionData();
    },
    handleCurrentChangeSafetyInspectionPageInfo(val) {
      this.safetyInspectionPageInfo.pageIndex = val;
      this.fetchSafetyInspectionData();
    },
  },
};
</script>

<style lang="scss" scoped>
.taskPoint {
  border-radius: 50%;
  background-color: rgb(217, 0, 27);
  width: 6px;
  height: 6px;
  position: absolute;
  left: 35%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.taskPoint.green {
  background-color: rgb(102, 205, 0);
}
.leftBtn {
  cursor: pointer;
  color: #00f;
  text-decoration: underline;
}
</style>
